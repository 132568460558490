.search-suggestion {
	text-align: center;

	margin: 8px;
	padding: 10px 20px;
	border-radius: 100vh;
	cursor: pointer;
	
	background-color: black;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.5rem;
}