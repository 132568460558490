html {
	display: grid;

	height: fit-content;
	min-height: 100%;

	width: fit-content;
	min-width: 100%;
}

body {
	width: 100%;
	height: 100%;
}

#root {
	width: 100%;
	height: 100%;
}