.app {
	width: 100%;
	height: 100%;

	justify-content: center;
}

.app > ._content {
	flex-direction: column;
	width: 100%;
}

@media screen and (min-width: 1280px) {
	.app > ._content {
		width: 90%;
	}
}

@media screen and (min-width: 1920px) {
	.app > ._content {
		width: 80%;
	}
}